import React, { useRef, useState, useEffect } from "react";
import { data, pics } from "../data/data.js";
import Masonry from "react-masonry-css";
import LightGalleryComponent from "lightgallery/react";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lightGallery from "lightgallery";
import Transitions from './Transition'

import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { TextBlock, MediaBlock, TextRow, RectShape, RoundShape } from 'react-placeholder/lib/placeholders';

const Work = () => {
  const [loadedImages, setLoadedImages] = useState([]);

  const awesomePlaceholder = (
    <div className='my-awesome-placeholder h-60 flex items-center bg-stone-400  my-2'>
      <RectShape color='#e7e5e4' style={{ margin: 0 }}/>
    </div>
  );

  const projects = pics;
  const lightboxRef = useRef(null);

  useEffect(() => {
    const loadImage = async (src, id) => {
      try {
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            setLoadedImages((prevLoadedImages) => [...prevLoadedImages, id]);
            resolve();
          };
          img.onerror = reject;
          img.src = src;
        });
      } catch (error) {
        console.error(`Error loading image with id ${id}:`, error);
      }
    };

    const loadImages = async () => {
      projects.forEach((project) => {
        const imageId = generateImageId(project); // Replace this with a function that generates a unique identifier for each image
        loadImage(project, imageId);
      });
    };

    loadImages();
  }, [projects]);

  const generateImageId = (src) => {
    // Implement a logic here to generate a unique identifier for each image
    // You can use a library like uuid or come up with your own logic
    return src; // For simplicity, we're using the image source as the identifier
  };

  return (
    <Transitions>
      <div className="px-4 max-w-6xl mx-auto my-6">
        <Masonry breakpointCols={2} className="flex gap-4" columnClassName="">
          {projects.map((project, idx) => {
            const imageId = generateImageId(project);
            return (
              <ReactPlaceholder ready={loadedImages.includes(imageId)} showLoadingAnimation  customPlaceholder={awesomePlaceholder} key={idx}>
                {loadedImages.includes(imageId) && (
                  <img
                  loading="lazy"
                    src={project}
                    alt={"project image" + idx}
                    className="my-4 hover:opacity-90 cursor-pointer"
                    onClick={() => {
                      lightboxRef.current?.openGallery(idx);
                    }}
                  />
                )}
              </ReactPlaceholder>
            );
          })}
        </Masonry>
        <LightGalleryComponent
          onInit={(ref) => {
            if (ref) {
              lightboxRef.current = ref.instance;
            }
          }}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          dynamic
          dynamicEl={projects.map((project) => ({
            src: project,
            thumb: project,
          }))}
        />
      </div>
    </Transitions>
  );
};

export default Work;
