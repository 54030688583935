import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import { slides } from "../data/data";
import { pics } from "../data/data.js";
import Transitions from './Transition'

const Home = () => {
  const mainPics = pics;

  return (
    <Transitions>
    <div>
      <div>
        <div className="">
          <Carousel
            showIndicators={false}
            swipeable={true}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            infiniteLoop={true}
            autoPlay={true}
            className="max-h-[85vh] overflow-hidden flex items-center"  
          >
            <div className="">
              <img src={mainPics[29]} />
            </div>
            <div className="">
              <img src={mainPics[30]} />
            </div>
            <div className=" ">
              <img src={mainPics[31]} />
            </div>
          </Carousel>
        </div>
        <div className="bg-[#242323ff] text-white text-center py-8 px-6 w-full">
          <h2 className="mb-6 text-3xl">Наш подход</h2>
          <div className="max-w-lg text-center mx-auto  divide-y divide-neutral-700 [&>*]:py-6">
          <p >
            Мы создаем интерьеры для счастливой жизни! Разработаем красивый и
            функциональный дизайн интерьера, подберем все материалы и мебель,
            предоставим отличную бригаду для воплощения проекта в жизнь.
          </p>
          <p >
            Независимо от того, начинаете ли вы с нуля или надеетесь вдохнуть
            новую жизнь в старый дом, наша команда обладает талантом дизайнера и
            техническим опытом, чтобы сделать процесс максимально эффективным и
            создать что-то удивительное.
          </p></div>
        </div>
      </div>
    </div>
    </Transitions>
  );
};

export default Home;
