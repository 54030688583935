import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo-black.png";
const Footer = () => {
  return (
    <div className="bg-[#f7f6f2]  ">
      <p></p>
      <ul className="flex md:flex-row flex-col justify-center items-center  uppercase text-xs font-semibold gap-6  pb-10 md:pb-0">
        <li className="overflow-hidden">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo Image"
              style={{ width: "100px", height: "100px" }}
            />
          </Link>
        </li>
        <li>
          <a href="/about">О Нас</a>
        </li>
        <li>
          <a href="/Services">Услуги</a>
        </li>
        <li>
          <a href="/work">Портфолио</a>
        </li>
        <li>
          <a href="/contact">Контакты</a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
