import React from "react";
import { useState } from "react";
import { pics } from "../data/data.js";
import { IoIosArrowUp } from "react-icons/io";
import Transitions from "./Transition";

const Skills = () => {
  const [activeTab, setActiveButton] = useState(null);

  const onTabClickHandler = (buttonId) => {
    if (activeTab === buttonId) {
      // If the same button is clicked again, close it
      setActiveButton(null);
    } else {
      // Close the previously active button and open the clicked button
      setActiveButton(buttonId);
    }
  };

  return (
    <Transitions>
      <div className="bg-[#f7f6f2] w-full h-full">
        <div className="flex flex-col justify-center  max-w-5xl m-auto">
          <div className="flex-row md:flex items-center md:mt-[40px] mb-[50px] md:p-4">
            <img className="m-auto md:max-w-lg" src={pics[5]} alt="" />
            <div className="flex-col mt-10 px-6 md:ml-14 md:px-0">
              <h2 className="text-3xl pb-4">Наша философия</h2>
              <p>
                Мы всегда считали, что дом оживает, когда четкие современные
                линии сочетаются с более теплыми текстурами и природными
                элементами. Именно эта фирменная эстетика теплого модерна в
                сочетании с нашей оптимизированной системой управления проектами
                отличает нас от других и обеспечивает нам международное
                признание.
              </p>
            </div>
          </div>
        </div>

        <div className=" md:flex flex-col text-center  bg-[#242323ff] text-[#fbf9f9ff] ">
          <h2 className="p-12 text-3xl">Что мы делаем</h2>
          <div className="flex md:flex-row flex-col md:divide-x divide-y md:divide-y-0 divide-neutral-700 items-center justify-center  max-w-5xl m-auto [&>*]:p-7">
            <h3>Планирование проекта Консультации по планировке </h3>
            <h3>Всесторонний дизайн интерьера</h3>
            <h3>Полный комплекс услуг Мебель и стиль</h3>
            <h3>Управление проектом и администрация</h3>
          </div>
          <button className="text-sm mb-16 mt-10 w-40 h-10 m-auto text-black bg-[#fbf9f9ff] hover:bg-stone-300">
            Связаться
          </button>
        </div>

        <h2 className="my-12 text-center text-3xl">Наши цены</h2>

        <div className="flex flex-col justify-center max-w-2xl md:flex-row m-auto gap-10 pb-16 px-8">
          <div className="flex-1 p-4 shadow-lg hover:bg-stone-300">
            basic
            <h3 class="text-2xl mb-6">
              <strong>$99</strong>
              <small class="text-gray-500 text-sm">/ft</small>
            </h3>
            <ol className="text-left">
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                первое
              </li>
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                второе
              </li>
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                третье
              </li>
            </ol>
          </div>
          <div className="flex-1  shadow-lg p-4 hover:bg-stone-300">
            advanced
            <h3 class="text-2xl mb-6">
              <strong>$199</strong>
              <small class="text-gray-500 text-sm">/ft</small>
            </h3>
            <ol className="text-left">
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                первое
              </li>
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                второе
              </li>
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                третье
              </li>
            </ol>
          </div>
          <div className="flex-1 shadow-lg p-4 hover:bg-stone-300">
            enterprise
            <h3 class="text-2xl mb-6">
              <strong>$299</strong>
              <small class="text-gray-500 text-sm">/ft</small>
            </h3>
            <ol className="text-left">
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                первое
              </li>
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                второе
              </li>
              <li class="mb-4 flex items-center">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="check"
                  class="text-green-600 w-4 h-4 mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                третье
              </li>
            </ol>
          </div>
        </div>

        <div className=" md:flex flex-col text-center bg-[#242323ff] text-[#fbf9f9ff] [&>*]:py-6 p-6 ">
          <h2 className="text-3xl mt-5">
            Вам нужен дизайн с полным спектром услуг прямо сейчас?
          </h2>
          <div className="max-w-2xl m-auto">
            <p>
              Мы также предлагаем одночасовые виртуальные консультации по
              дизайну интерьера с нашими старшими дизайнерами, чтобы помочь вам
              с планированием пространства, выбором отделки и светильников и
              другими вопросами дизайна.
            </p>
          </div>
          <div className="mb-5">
            <button className="text-sm  text-black p-3 w-40 bg-[#fbf9f9ff] hover:bg-stone-300">
              Связаться
            </button>
          </div>
        </div>

        <h2 className="px-6 mt-6 text-center text-3xl">
          Наш полный процесс обслуживания
        </h2>

        <div className=" max-w-5xl m-auto p-10 divide-y divide-neutral-300 [&>*]:py-5 [&>*]:px-2">
          <div
            onClick={() => onTabClickHandler(1)}
            className={`cursor-pointer ${activeTab === 1 ? "active" : ""}`}
          >
            <div className=" text-sm font-medium w-full">
              <div className="flex flex-row justify-between items-center">
                <h3>ЭТАП I: АНКЕТА </h3>
                <span
                  className={`transition-transform duration-300 transform ${
                    activeTab === 1 ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <IoIosArrowUp />
                </span>
              </div>
            </div>
            <div
              className={`transition-max-h duration-300 overflow-hidden ${
                activeTab === 1 ? "max-h-[600px]" : "max-h-0"
              }`}
            >
              <p className="my-4">
                Каждый проект AURA Interiors начинается с нашей анкеты. Мы
                просим вас предоставить нам как можно больше информации, включая
                вдохновляющие фотографии и планы/фотографии, если они у вас
                есть. Этот шаг важен, так как он помогает нам оценить,
                соответствуют ли наш процесс и внешний вид вашему проекту.
              </p>
            </div>
          </div>

          <div
            onClick={() => onTabClickHandler(2)}
            className={`cursor-pointer ${activeTab === 2 ? "active" : ""}`}
          >
            <div className="text-sm font-medium w-full ">
              <div className="flex flex-row justify-between items-center">
                <h3>ЭТАП II: ОТКРЫТЫЙ ЗВОНОК + ЦЕНА</h3>
                <span
                  className={`transition-transform duration-300 transform ${
                    activeTab === 2 ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <IoIosArrowUp />
                </span>
              </div>
            </div>
            <div
              className={`transition-max-h ease-in duration-300 overflow-hidden ${
                activeTab === 2 ? "max-h-[600px]" : "max-h-0"
              }`}
            >
              <p className="my-4">
                Если ваш проект кажется вам подходящим, наш менеджер по дизайну
                запланирует с вами предварительный звонок, чтобы обсудить проект
                более подробно и убедиться, что мы подходим для этой работы.
                Оттуда мы отправим предложение, которое включает в себя расценки
                на наши профессиональные гонорары, а также реалистичный график
                проекта.
              </p>
            </div>
          </div>

          <div
            onClick={() => onTabClickHandler(3)}
            className={`cursor-pointer ${activeTab === 3 ? "active" : ""}`}
          >
            <div className="text-sm font-medium">
              <div className="flex flex-row justify-between items-center">
                <h3> ЭТАП III: ПРЕДПРОЕКТИРОВАНИЕ </h3>
                <span
                  className={`transition-transform duration-300 transform ${
                    activeTab === 3 ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <IoIosArrowUp />
                </span>
              </div>
            </div>
            <div
              className={`transition-max-h duration-300 overflow-hidden ${
                activeTab === 3 ? "max-h-[600px]" : "max-h-0"
              }`}
            >
              <p className="my-4">
                После подписания контракта и внесения предоплаты мы переходим к
                этапу предварительного проектирования. Поскольку у нас обычно
                все расписано за несколько месяцев до следующей доступности, мы
                используем эти месяцы, чтобы позаботиться о таких вещах, как
                завершение бюджета + график, связь с вашим строителем +
                архитектором и определение направления проектирования.
              </p>
            </div>
          </div>
          <div
            onClick={() => onTabClickHandler(4)}
            className={`cursor-pointer ${activeTab === 4 ? "active" : ""}`}
          >
            <div className="text-sm font-medium">
              <div className="flex flex-row justify-between items-center">
                <h3>ЭТАП IV: ДЕНЬ НАЧАЛА </h3>
                <span
                  className={`transition-transform duration-300 transform ${
                    activeTab === 4 ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <IoIosArrowUp />
                </span>
              </div>
            </div>
            <div
              className={`transition-max-h duration-300 overflow-hidden ${
                activeTab === 4 ? "max-h-[600px]" : "max-h-0"
              }`}
            >
              <p className="my-4">
                Официальный первый день этапа разработки вашего проекта. После
                того, как мы позаботились о некоторых важных элементах
                предварительного проектирования, мы готовы прыгнуть вперед и
                приступить к интересным делам.
              </p>
            </div>
          </div>
          <div
            onClick={() => onTabClickHandler(5)}
            className={`cursor-pointer ${activeTab === 5 ? "active" : ""} `}
          >
            <div className="text-sm font-medium">
              <div className="flex flex-row justify-between ">
                <h3> ЭТАП V: ДИЗАЙН ИНТЕРЬЕРА </h3>
                <span
                  className={`transition-transform duration-300 transform ${
                    activeTab === 5 ? "rotate-0" : "rotate-180"
                  }`}
                >
                  <IoIosArrowUp />
                </span>
              </div>
            </div>
            <div
              className={`transition-max-h duration-300 overflow-hidden ${
                activeTab === 5 ? "max-h-[600px]" : "max-h-0"
              }`}
            >
              <p className="my-4 ">
                Основываясь на установленном бюджете и направлении, мы
                приступаем к проектированию всего вашего дома, учитывая каждую
                деталь, от гаражных ворот до цвета затирки. Через несколько
                недель работы вы получите по электронной почте ссылку на
                презентацию дизайна.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Transitions>
  );
};
{
  /* <p className="text-center px-10">
            Мы создаем интерьеры для счастливой жизни! Разработаем красивый и
            функциональный дизайн интерьера, подберем все материалы и мебель,
            предоставим отличную бригаду для воплощения проекта в жизнь.
          </p> */
}
export default Skills;
