import React from "react";
import Transitions from "./Transition";

const Contact = () => {
  return (
    <Transitions>
      <div
        name="contact"
        className="w-full h-screen bg-[#fbf9f9ff] flex justify-center py-8 px-4"
      >
        <form
          method="POST"
          action="https://getform.io/f/a699a1b2-f225-434e-b317-1fbbde8e006c"
          className="flex flex-col max-w-2xl w-full"
        >
          <div className="pb-8">
            <p className="text-3xl inline  text-black">Контакты</p>
            <p className="text-gray-300 py-4"> Свяжитесь с нами</p>
          </div>
          <input
            className="bg-[#eceae7] p-2"
            type="text"
            placeholder="Имя"
            name="name"
          />
          <input
            className="my-4 p-2 bg-[#eceae7]"
            type="email"
            placeholder="Email"
            name="email"
          />
          <textarea
            className="bg-[#eceae7] p-2"
            name="message"
            rows="10"
            placeholder="Сообщение"
          ></textarea>
          <button className="text-black  hover:bg-white bg-[#eceae7] px-4 py-3 my-8 mx-auto flex items-center">
            Отправить
          </button>
        </form>
      </div>
    </Transitions>
  );
};

export default Contact;
