import arch1 from "../assets/projects/arch1.png";
import arch2 from "../assets/projects/arch2.png";
import bedroom from "../assets/projects/bedroom.png";
import bedroom2 from "../assets/projects/bedroom2.jpg";
import bedroom3 from "../assets/projects/bedroom3.png";
import bedroom4 from "../assets/projects/bedroom4.png";
import bedroom5 from "../assets/projects/bedroom5.png";
import classic1 from "../assets/projects/classic1.jpg";
import classic2 from "../assets/projects/classic2.jpg";
import img1 from "../assets/projects/img1.PNG";
import img2 from "../assets/projects/img2.PNG";
import img4 from "../assets/projects/img4.jpg";
import img5 from "../assets/projects/img5.PNG";
import img6 from "../assets/projects/img6.PNG";
import img7 from "../assets/projects/img7.PNG";
import img8 from "../assets/projects/img8.PNG";
import img9 from "../assets/projects/img9.PNG";
import img10 from "../assets/projects/img10.PNG";
import img11 from "../assets/projects/img11.jpg";
import img12 from "../assets/projects/img12.jpg";
import img13 from "../assets/projects/img13.jpg";
import img14 from "../assets/projects/img14.jpg";
import img15 from "../assets/projects/img15.jpg";
import img16 from "../assets/projects/img16.jpg";
import img17 from "../assets/projects/img17.jpg";
import img18 from "../assets/projects/img18.png";
import img19 from "../assets/projects/img19.PNG";
import img20 from "../assets/projects/img20.PNG";
import img21 from "../assets/projects/img21.jpg";
import main1 from "../assets/projects/main1.jpg";
import main2 from "../assets/projects/main2.PNG";
import main3 from "../assets/projects/main3.jpg";
import man from "../assets/projects/main1.jpg";

export const pics = [
  arch1,
  arch2,
  bedroom,
  bedroom2,
  bedroom3,
  bedroom4,
  bedroom5,
  classic1,
  classic2,
  img1,
  img2,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  main1,
  main2, 
  main3
];
