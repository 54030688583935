import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import Logo from "../assets/logo-new.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleClick = () => {
    setNav(!nav);
    if (!nav) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const scrollHeight = document.body.offsetHeight - window.innerHeight;

    if (currentScrollPos < 100) {
      setVisible(true);
    } else if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else if (currentScrollPos > scrollHeight - 1) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

 

  // Add event listeners to handle scroll and touchmove
  window.addEventListener("scroll", handleScroll);


  // Clean up event listeners
  const cleanup = () => {
    window.removeEventListener("scroll", handleScroll);
  };

  return (
    <nav
      className={`w-full h-[100px]  flex justify-evenly items-center px-4 bg-[#1b1b1d] text-[#d8d5d0]  z-30 sticky ${
        visible ? "top-0" : ""
      } `}
      style={{
        transition: "transform 0.5s ease-in-out",
        transform: visible ? "translateY(0)" : "translateY(-100%)",
      }}
    >
      <div className="mx-auto md:hidden pl-4">
        <Link to="/">
          <img src={Logo} alt="Logo Image" className="w-[130px]" />
        </Link>
      </div>
      {/* menu */}
      <ul className="hidden md:flex items-center uppercase h-24 text-sm font-medium gap-8 pl-12 overflow-hidden ">
        <li className="hover:text-zinc-400 ">
          <a href="/about">О Нас</a>
        </li>
        <li className="hover:text-zinc-400">
          <a href="/Services">Услуги</a>
        </li>
        <li className="hover:opacity-70 w-[130px] mx-12">
          <Link to="/"  >
            <img src={Logo} alt="Logo Image" />
          </Link>
        </li >
        <li className="hover:text-zinc-400">
          <a href="/work">Портфолио</a>
        </li>
        <li className="hover:text-zinc-400">
          <a href="/contact">Контакты</a>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-20 cursor-pointer">
        {!nav ? <FaBars size={25} color="#d8d5d0"/> : <FaTimes size={25} color="#d8d5d0" />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "z-10 fixed top-0 left-0 w-full h-screen bg-[#1b1b1d] flex flex-col items-center text-center "
        }
      >
        <li className="mt-10 py-6 text-3xl">
          <a href="/">Главная</a>
        </li>
        <li className="py-6 text-3xl">
          <a href="/about">О Нас</a>
        </li>
        <li className="py-6 text-3xl">
          <a href="/services">Услуги</a>
        </li>
        <li className="py-6 text-3xl">
          <a href="/work">Портфолио</a>
        </li>
        <li className="py-6 text-3xl">
          <a href="/contact">Контакты</a>
        </li>
        <li className="pt-20 text-3xl">+374 123 4567</li>
      </ul>
    </nav>
  );
};

export default Navbar;
