import React from "react";
import { pics } from "../data/data.js";
import Transitions from './Transition'

const About = () => {
  return (
    <Transitions>
      <div name="about" className="bg-[#f7f6f2]  text-black">
        <div className="flex xl:flex-row flex-col m-auto justify-between items-center">
          <img className="md:max-w-3xl " src={pics[15]} alt="" />
          <div className="grow mb-6">
            <div className="md:max-w-2xl flex-col mt-10 mx-auto px-10 [&>*]:mb-5">
              <h2 className="text-3xl">Наша история</h2>
              <p>
                Их любовь к творчеству — вот что объединило эту команду мужа и
                жены много лет назад. Их путешествие началось как страстный
                проект и со временем превратилось в многопрофильную компанию,
                включающую фирму по дизайну интерьеров, бутик-магазин и аренду
                предметов роскоши. Их миссия состоит в том, чтобы создавать
                красивые пространства, вдохновляя и давая возможность другим
                делать то же самое.
              </p>
              <p>
                В то время как наши проекты теперь воплощаются в жизнь целой
                командой талантливых дизайнеров, соучредители Мелисса и Саша
                продолжают участвовать в творческом процессе и по-прежнему
                проверяют все, что мы представляем нашим клиентам. Их влияние и
                опыт можно найти в каждом пространстве, которое мы создаем.
              </p>
              <p>
                {" "}
                В качестве генерального директора Мелисса работает с командой
                дизайнеров, чтобы гарантировать, что качество обслуживания наших
                клиентов всегда улучшается, и что каждое предоставляемое нами
                пространство соответствует уникальным потребностям наших
                клиентов, пересматривая дизайн и процесс на каждом этапе пути.
              </p>
              <p>
                Саша, президент и креативный директор, особенно увлечен
                нестандартным дизайном и элементами освещения, которые делают
                пространство более возвышенным. В рамках каждого проекта Саша
                призывает наших дизайнеров вводить новшества и расставлять
                приоритеты в деталях.
              </p>
              <p>
                AURA Interiors — это фирма, предоставляющая полный спектр услуг
                по дизайну интерьеров, основное внимание в которой уделяется
                ремонту и новостройкам высокого класса. Наша талантливая команда
                дизайнеров обслуживает клиентов по всей Северной Америке,
                используя ведущее в отрасли программное обеспечение и охватывая
                все современные средства цифровой связи для бесперебойной работы
                с домовладельцами по всему континенту.
              </p>
            </div>
            <div></div>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-full  py-6 bg-[#242323ff] text-white h-xl">
          <div className="flex-col max-w-5xl [&>*]:p-6 px-10 text-center divide-y divide-neutral-700 ">
            {/* <h1 className="text-center">Команда дизайнеров AURA</h1> */}
            <p>
              AURA - это та энергетика, которую ощущаешь приходя домой, отдыхая
              от ритма современной жизни, наполняясь для достижения новых
              вершин.{" "}
            </p>
            <p>
              AURA – это команда талантливых профессионалов, которая стремится
              создавать современный, эстетичный, функциональный дизайн квартир,
              домов и общественных пространств.
            </p>
            <p>
              Нашей миссией является создание нового качества жизни. Интерьер и
              архитектура — это то, что мы делаем с любовью, поэтому наши
              клиенты всегда остаются довольны.
            </p>
          </div>
        </div>
      </div>
    </Transitions>
  );
};

export default About;
